<style lang="less" src="./styles/DFStyle_SiteIndex_Template.less"></style>
<style>
body {
    position: relative;
}
</style>
<style lang="less" scoped>
.dropdown-toggle {
    cursor: pointer;
}
.user-control a {
    color: #fff;
    &.icon {
        display: flex;
        align-items: center;
        text-decoration: none;
        &:before {
            color: #fff;
            margin-right: 0.5rem;
        }
        &:after {
            color: #fff;
            margin-left: 0.5rem;
        }
    }
}
.navbar .user-menu {
    margin-top: 10px;
}
</style>
<template>
    <Promised :promise="dataPromise" v-slot:combined="{ isPending, isDelayOver, data, error }">
        <LoadingWheel :width="100" v-if="isPending && isDelayOver && data == null" />
        <template v-if="error != null">
            <div>An error occurred!</div>
        </template>
        <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Open+Sans:400,600,700" @load="setLoaded('opensans')" />
        <template v-if="data != null && !isAdminComponent">
            <Promised :promise="siteDesignIndexStylesUrl" v-slot:combined="{ data: resolvedUrl }">
                <link rel="stylesheet" :href="resolvedUrl" @load="setLoaded('siteIndexCss')" />
            </Promised>
            <template v-if="component != 'IndexPage' || loaded.siteCss || shouldLoadSiteCss">
                <nonIndexStyles :siteDesignStylesUrl="siteDesignStylesUrl" @load="setLoaded('siteCss')" />
            </template>
            <template v-else>
                <Promised :promise="siteDesignStylesUrl" v-slot:combined="{ data: resolvedUrl }"><link rel="prefetch" :href="resolvedUrl" /></Promised>
            </template>
        </template>
        <template v-if="isAdminComponent">
            <AdminStyles />
        </template>
        <LoadingWheel :width="100" v-show="data != null && !allLoaded" />
        <div v-show="allLoaded" v-if="data != null" @click="globalClick($event)">
            <SessionExpiringModal v-if="showLoginExpiringBanner" :as-modal="false" :expireTime="sessionTimeout" :isLoggedIn="true" />
            <VersionMismatchBanner v-if="hasVersionMismatch" />
            <!-- <pre>HS:{{ hasSession }}, RST: {{ relativeSessionTimeout }}</pre> -->
            <!-- Admin Top Navigation -->
            <nav id="AdminTopNavigation" class="navbar flex-md-nowrap p-0" v-if="isAdminComponent && !isPageEditorComponent" role="banner">
                <div class="navbar-brand">
                    <img v-if="isBridgeBrandEnabled" src="./images/eb_logo_white_bridge.png" alt="AccessGov" />
                    <img v-else src="./images/eb_logo_white.png" alt="AccessGov" />
                </div>
                <div class="navbar-right ml-auto form-inline">
                    <div class="tenant-control">
                        <!-- TODO: Use Display Label and show caret with dropdown-toggle class if user has more than one tenant to choose from -->
                        <div class="">{{ data.tenantData.tenantDisplayLabel }}</div>
                    </div>
                    <div v-show="isLoggedIn" class="user-control">
                        <div class="dropdown">
                            <a role="button" tabindex="0" class="dropdown-toggle icon icon-user-solid" @click.prevent.stop="toggleDropdown($event)" aria-haspopup="menu" aria-controls="AdminUserMenu" aria-label="User Name and Options Toggle">
                                {{ data.userData == null ? null : data.userData.UserName }}
                            </a>
                            <ul class="dropdown-menu user-menu" role="menu" id="AdminUserMenu" aria-label="User Options">
                                <li v-show="hasMultiFactorAuthenticationFeature">
                                    <Promised :promise="getUrl('multiFactorAuthenticationIndex')" v-slot:combined="{ data: resolvedUrl }">
                                        <a @click.prevent="changePage({ page: 'MultiFactorAuthenticationIndex' })" :href="resolvedUrl" role="menuitem">MFA Settings</a>
                                    </Promised>
                                </li>
                                <Promised :promise="getLogoutUrl()" v-slot:combined="{ data: resolvedUrl }">
                                    <li>
                                        <a :href="resolvedUrl" role="menuitem"> Log Out </a>
                                    </li>
                                </Promised>
                            </ul>
                        </div>
                    </div>
                    <div v-show="!isLoggedIn" class="user-control">
                        <Promised :promise="getLoginUrl(1)" v-slot:combined="{ data: resolvedUrl }">
                            <a :href="resolvedUrl">Log In</a>
                        </Promised>
                    </div>
                </div>
            </nav>
            <!-- Admin Left Navigation -->
            <nav id="AdminLeftNavigation" class="sidebar-sticky left-nav-pri live flex-column d-flex" v-if="isAdminComponent && !isPageEditorComponent && data.userData != null" role="navigation" aria-label="Admin Links">
                <ul class="nav flex-column">
                    <li class="nav-item">
                        <Promised :promise="getUrl('admin')" v-slot:combined="{ data: resolvedUrl }">
                            <a class="nav-link icon icon-lg-home" :class="component == 'Admin' ? ['active'] : []" @click.prevent="changePage({ page: 'Admin' })" :href="resolvedUrl"><div class="nav-caption">Dashboard</div></a>
                        </Promised>
                    </li>
                    <li class="nav-item" v-show="data.userData.CanTestForms || data.userData.CanReview" v-if="hasPageworkflowFeature">
                        <Promised :promise="getUrl('adminTasksIndex')" v-slot:combined="{ data: resolvedUrl }">
                            <a class="nav-link icon icon-lg-tasks" :class="component == 'AdminTasksIndex' ? ['active'] : []" @click.prevent="changePage({ page: 'AdminTasksIndex' })" :href="resolvedUrl"><div class="nav-caption">Tasks</div></a>
                        </Promised>
                    </li>
                    <li class="nav-item" v-show="data.userData.CanTestForms || data.userData.CanReview">
                        <Promised :promise="getUrl('adminSearch')" v-slot:combined="{ data: resolvedUrl }">
                            <a class="nav-link icon icon-lg-submissions" :class="component == 'AdminSearch' ? ['active'] : []" @click.prevent="changePage({ page: 'AdminSearch' })" :href="resolvedUrl"><div class="nav-caption">Submissions</div></a>
                        </Promised>
                    </li>
                    <li class="nav-item" v-show="data.userData.CanEditForms">
                        <Promised :promise="getUrl('adminPageIndex')" v-slot:combined="{ data: resolvedUrl }">
                            <a class="nav-link icon icon-lg-sites" :class="component == 'AdminPage' || component == 'AdminPageNew' || component == 'AdminPageDiscontinue' || component == 'AdminPageTransfer' || component == 'AdminPageEdit' || component == 'AdminGuidedSearch' || component == 'AdminWidget' ? ['active'] : []" @click.prevent="changePage({ page: 'AdminPage' })" :href="resolvedUrl"><div class="nav-caption">Sites</div></a>
                        </Promised>
                    </li>
                    <li class="nav-item" v-show="data.userData.CanViewDataSources">
                        <Promised :promise="getUrl('adminDataSources', { id: null })" v-slot:combined="{ data: resolvedUrl }">
                            <a class="nav-link icon icon-lg-data" :class="component == 'AdminDataSources' ? ['active'] : []" @click.prevent="changePage({ page: 'AdminDataSources', settings: { id: null } })" :href="resolvedUrl"><div class="nav-caption">Data Sources</div></a>
                        </Promised>
                    </li>
                    <li class="nav-item" v-show="data.userData.CanManageUsers">
                        <Promised :promise="getUrl('adminUserManagementUsers')" v-slot:combined="{ data: resolvedUrl }">
                            <a class="nav-link icon icon-lg-users" :class="component == 'AdminUserManagementUsers' || component == 'AdminUserManagementGroups' ? ['active'] : []" @click.prevent="changePage({ page: 'AdminUserManagementUsers' })" :href="resolvedUrl"
                                ><div class="nav-caption">User<br />Management</div></a
                            >
                        </Promised>
                    </li>
                </ul>
                <ul class="nav flex-column admin-bottom-links">
                    <li class="nav-item" v-show="(data.userData.CanViewAllTenantsBilling || data.userData.CanViewTenantBilling) && hasBillingPageFeature">
                        <Promised :promise="getUrl('adminBilling', { includeAllTenants: false, start: null, end: null })" v-slot:combined="{ data: resolvedUrl }">
                            <a class="nav-link icon icon-lg-billing" :class="component == 'AdminBilling' ? ['active'] : []" @click.prevent="changePage({ page: 'AdminBilling', settings: { includeAllTenants: false, start: null, end: null } })" :href="resolvedUrl"><div class="nav-caption">Billing</div></a>
                        </Promised>
                    </li>
                    <li class="nav-item" v-show="data.userData.CanViewAllTenantsStats || (data.userData.CanViewTenantStats && hasTenantStatsFeature)">
                        <Promised :promise="getUrl('adminStats', { includePageDetails: false, includeAllTenants: false, start: null, end: null })" v-slot:combined="{ data: resolvedUrl }">
                            <a class="nav-link icon icon-lg-stats" :class="component == 'AdminStats' ? ['active'] : []" @click.prevent="changePage({ page: 'AdminStats', settings: { includePageDetails: false, includeAllTenants: false, start: null, end: null } })" :href="resolvedUrl"><div class="nav-caption">Stats</div></a>
                        </Promised>
                    </li>
                    <li class="nav-item" v-show="data.userData.CanViewFeatureFlags">
                        <Promised :promise="getUrl('adminFeatureFlags')" v-slot:combined="{ data: resolvedUrl }">
                            <a class="nav-link icon icon-lg-flag" :class="component == 'AdminFeatureFlags' ? ['active'] : []" @click.prevent="changePage({ page: 'AdminFeatureFlags' })" :href="resolvedUrl"><div class="nav-caption">Feature Flags</div></a>
                        </Promised>
                    </li>
                    <li class="nav-item" v-show="data.userData.CanManageTenants">
                        <Promised :promise="getUrl('adminTenantManagementIndex')" v-slot:combined="{ data: resolvedUrl }">
                            <a class="nav-link icon icon-lg-settings" :class="component == 'AdminTenantManagementIndex' || component == 'AdminTenantManagementEdit' ? ['active'] : []" @click.prevent="changePage({ page: 'AdminTenantManagementIndex' })" :href="resolvedUrl"><div class="nav-caption">Tenants</div></a>
                        </Promised>
                    </li>
                </ul>
            </nav>
            <!-- Public Top Navigation -->
            <div id="public-nav-wrapper" v-if="!isAdminComponent">
                <div id="layoutContainer">
                    <nav class="navbar fixed-top navbar-expand-md navbar-inverse navbar-dark" role="banner">
                        <div class="container-fluid">
                            <button type="button" class="navbar-toggler pull-right" data-toggle="collapse" data-target="#collapse2" aria-expanded="false" @click.capture="toggleMobileNav">
                                <span class="sr-only">Toggle navigation</span>
                                <span class="icon-bar"></span>
                                <span class="icon-bar"></span>
                                <span class="icon-bar"></span>
                            </button>
                            <div class="navbar-brand">
                                <picture>
                                    <source :srcset="data.tenantData.logoUrl + '&height=50 1x, ' + data.tenantData.logoUrl + '&height=75 1.5x, ' + data.tenantData.logoUrl + '&height=100 2x, ' + data.tenantData.logoUrl + '&height=200 4x'" />
                                    <img :src="data.tenantData.logoUrl + '&height=50'" height="50" :alt="(data.tenantData.logoAlt || data.tenantData.tenantDisplayLabel) + ' - AccessGov: Empowered by Tyler Technologies'" />
                                </picture>
                            </div>
                            <a class="d-block d-md-none search-toggle nav-item nav-link" @click="showSearch(data)" v-if="data.userData != null && data.userData.CanUserSearchPages">
                                <span class="sr-only">Search</span>
                                <span class="icon icon-search"></span>
                            </a>
                            <div class="navbar-collapse" :class="showMobileNav ? [] : ['collapse']" id="collapse2" @click="toggleMobileNav">
                                <ul class="nav navbar-nav navbar-right">
                                    <!-- @Html.RenderResources(LayoutInsertions.PreHeaderLinks) -->
                                    <li class="d-none d-md-block search-toggle nav-item" id="searchNavLink" v-if="data.userData != null && data.userData.CanUserSearchPages">
                                        <a class="nav-link" role="button" tabindex="0" @click="showSearch(data)">
                                            <span class="icon icon-search"></span>
                                            <span>&nbsp;Search</span>
                                        </a>
                                    </li>
                                    <li id="homeNavLink" class="nav-item" v-show="data.userData != null && data.userData.CanUserViewHomePage">
                                        <Promised :promise="getUrl('index')" v-slot:combined="{ data: resolvedUrl }"><a role="button" tabindex="0" class="nav-link" @click.prevent="changePage({ page: 'Index' })" :href="resolvedUrl">Home</a></Promised>
                                    </li>
                                    <li id="directoryNavLink" class="nav-item" v-show="data.userData != null && data.userData.CanUserViewDirectory">
                                        <Promised :promise="getUrl('directory')" v-slot:combined="{ data: resolvedUrl }"><a role="button" tabindex="0" class="nav-link" @click.prevent="changePage({ page: 'Directory' })" :href="resolvedUrl">Directory</a></Promised>
                                    </li>
                                    <!-- @Html.RenderResources(LayoutInsertions.InnerHeaderLinks) -->
                                    <li class="nav-item user-control" v-show="isLoggedIn">
                                        <div class="dropdown">
                                            <a role="button" tabindex="0" class="dropdown-toggle nav-link icon icon-user-solid" @click.prevent.stop="toggleDropdown($event)" aria-haspopup="menu" aria-controls="PublicUserMenu" aria-label="User Name and Options Toggle">
                                                {{ data.userData != null ? data.userData.UserName : null }}
                                            </a>
                                            <ul class="dropdown-menu user-menu" role="menu" id="PublicUserMenu" aria-label="User Options">
                                                <!--@Html.RenderResources(LayoutInsertions.PreUserMenu) -->
                                                <li v-show="data.userData == null ? false : data.userData.IsAdmin">
                                                    <Promised :promise="getUrl('admin')" v-slot:combined="{ data: resolvedUrl }">
                                                        <a @click.prevent="changePage({ page: 'Admin' })" :href="resolvedUrl" role="menuitem">Admin Dashboard</a>
                                                    </Promised>
                                                </li>
                                                <li v-show="hasMultiFactorAuthenticationFeature">
                                                    <Promised :promise="getUrl('multiFactorAuthenticationIndex')" v-slot:combined="{ data: resolvedUrl }">
                                                        <a @click.prevent="changePage({ page: 'MultiFactorAuthenticationIndex' })" :href="resolvedUrl">MFA Settings</a>
                                                    </Promised>
                                                </li>
                                                <!--@Html.RenderResources(LayoutInsertions.InnerUserMenu) -->
                                                <li role="separator" class="divider"></li>
                                                <li>
                                                    <Promised :promise="getLogoutUrl()" v-slot:combined="{ data: resolvedUrl }">
                                                        <a :href="resolvedUrl"> Log Out </a>
                                                    </Promised>
                                                </li>
                                                <!--@Html.RenderResources(LayoutInsertions.PostUserMenu)-->
                                            </ul>
                                        </div>
                                    </li>
                                    <li class="login nav-item" v-show="!isLoggedIn && !isUserLoginDisabled">
                                        <Promised :promise="getLoginUrl(0)" v-slot:combined="{ data: resolvedUrl }"><a class="nav-link" :href="resolvedUrl"> Log In </a></Promised>
                                    </li>
                                    <!--@Html.RenderResources(LayoutInsertions.PostHeaderLinks)-->
                                </ul>
                            </div>
                        </div>
                    </nav>
                </div>
            </div>
            <!-- Body Content -->
            <div class="body-content" id="BodyContent">
                <keep-alive>
                    <component v-bind:is="component" v-on:change-page="changePage" v-on:update-page="updatePage" v-bind:settings="componentSettings"></component>
                </keep-alive>
            </div>
            <div id="public-footer-wrapper" v-show="component" v-if="!isAdminComponent && customFooterHtml !== undefined" role="contentinfo" aria-label="Footer">
                <div v-html="customFooterHtml" v-if="customFooterHtml != null"></div>
            </div>
            <div v-if="hasDeploymentToggle || hasOverrideableNow" style="z-index: 999999; position: fixed; display: inline-block; left: 0; right: 0; top: 0; text-align: center; background: black; color: red; margin: auto" :style="{ width: (hasDeploymentToggle ? 8 : 0) + (hasOverrideableNow ? 10 : 0) + 'em' }">
                <template v-if="hasDeploymentToggle">
                    <a target="SetDeploymentToggle" :href="data.userData?.DeploymentToggleUrl">{{ data.userData?.Deployment }}</a>
                </template>
                <template v-if="hasDeploymentToggle && hasOverrideableNow"> / </template>
                <template v-if="hasOverrideableNow">
                    <a target="SetFakeTime" :href="data.userData?.OverrideableNowUrl">
                        <span v-if="overriddenNow">{{ overriddenNow.toLocaleString() }}</span>
                        <strong v-else>NOT OVERRIDDEN</strong>
                    </a>
                </template>
            </div>
            <ModalsContainer />
            <LiveRegion />
        </div>
    </Promised>
</template>
<script lang="ts">
//cspell:ignore unref
import { defineComponent, defineAsyncComponent, ref, computed, watch, provide, Ref, onMounted, onUnmounted, nextTick, inject, triggerRef, markRaw, unref } from "vue";
//@ts-expect-error
import { ModalsContainer } from "vue-final-modal";
import ModalWrapper from "./ModalWrapper.3.vue";
import LoadingWheel from "./lib/LoadingWheel.3.vue";
import IndexPage from "./public/pages/Index.3.vue";
import LoginModal from "./LoginModal.3.vue";
import VersionMismatchBanner from "./VersionMismatchBanner.3.vue";
//import AdminIndex from "./admin/Index.3.vue";
import SessionExpiringModal from "./SessionExpiringModal.3.vue";
import LoadingPlaceholder from "./lib/LoadingPlaceholder.3.vue";
import { Promised } from "vue-promised";
import { useMainStore, TenantData } from "./mainStore";
import { cookieStore } from "./lib/cookie-store";
import { computedAsync } from "@vueuse/core";
import { UserData } from "@cs/UserData";
import resolvablePromise from "./lib/resolvablePromise";
import { changePageInjectKey, ChangePageEvent, updatePageInjectKey, getLoginUrlInjectKey, showModalInjectKey, loadUserDataInjectKey } from "./AppShell.3.vue.InjectKeys";
import { toggleDropdown } from "@/lib/toggleDropdown.3";
import { KnownFeatureFlags } from "@cs/KnownFeatureFlags";
import LiveRegion from "@/lib/LiveRegion.3.vue";
import NavSearchModal from "./NavSearchModal.3.vue";
let shouldSkipGaSetup = false;
declare global {
    interface Window {
        dataLayer: any[]; // eslint-disable-line @typescript-eslint/no-explicit-any
        gtag(...rest: Array<any>): void; // eslint-disable-line @typescript-eslint/no-explicit-any
        ga(...rest: Array<any>): void; // eslint-disable-line @typescript-eslint/no-explicit-any
    }
}
import { vfmInjectKey } from "./appExportKeys.3";
export default defineComponent({
    skipGaSetup() {
        shouldSkipGaSetup = true;
    },
    props: [],
    setup() {
        const vfm = inject(vfmInjectKey)!;
        if (vfm == null) throw new Error("Missing VFM");

        // State
        const currentUrl = ref(null as undefined | null | string);
        const isAdminComponent = ref(null as unknown as boolean);
        const isPageEditorComponent = ref(null as unknown as boolean);
        const showMobileNav = ref(false as boolean);
        const searchVisible = ref(false as boolean);
        const showAdminMenu = ref(false as boolean);
        const searchRootTenant = ref(false as boolean);
        const searchText = ref(null as string | null);
        const searchTextDebounced = ref(null as string | null);
        const component = ref(null as string | null | undefined);
        // TODO type instead of any
        const componentSettings = ref(null as any); // eslint-disable-line @typescript-eslint/no-explicit-any
        const tenantDataPromise = ref(null as null | Promise<TenantData>);
        const userDataPromise = ref(null as null | Promise<UserData>);
        const displayLabelCache = ref({} as { [key: string]: Promise<string> });
        const shouldLoadSiteCss = ref(false);
        const loaded = ref({
            opensans: false,
            siteIndexCss: false,
            siteCss: false,
        });
        const sessionTimeout = ref(null as Date | null);
        const showLoginExpiringBanner = ref(false);

        const mainStore = useMainStore();

        const siteDesignStylesUrl = computed(async () => {
            const options = { editorMode: false };
            if (userDataPromise.value != null && (await userDataPromise.value).ActiveFeatureFlags.includes("StyleEditorMode")) {
                options.editorMode = true;
            }
            return await getUrl("siteDesignStyles", options);
        });
        const siteDesignIndexStylesUrl = computed(async () => {
            const options = { editorMode: false };
            if (userDataPromise.value != null && (await userDataPromise.value).ActiveFeatureFlags.includes("StyleEditorMode")) {
                options.editorMode = true;
            }
            return await getUrl("siteDesignIndexStyles", options);
        });
        const needsLogin = computed(() => {
            return mainStore.needsLogin;
        });
        const isLoggedIn = computedAsync(async () => {
            return (await mainStore.userDataPromise).IsLoggedIn;
        });
        const hasSession = computed(() => {
            return mainStore.hasSession || isAdminComponent.value;
        });
        const hasVersionMismatch = computed(() => {
            return mainStore.hasVersionMismatch;
        });

        const promiseComputable = <TInput, TOutput>(promiseRef: Ref<Promise<TInput> | null>, func: (promise: Promise<TInput> | null) => Promise<TOutput>): Ref<TOutput> => {
            //VUE2 issue?
            return computedAsync(() => func(promiseRef.value)) as unknown as Ref<TOutput>;
        };

        const hasPageworkflowFeature = promiseComputable(userDataPromise, async i => (i == null ? undefined : (await i).ActiveFeatureFlags.includes("PageWorkflow") || null));
        const isUserLoginDisabled = promiseComputable(userDataPromise, async i => (i == null ? undefined : (await i).ActiveFeatureFlags.includes("DisableUserLogin") || null));
        const hasTenantStatsFeature = promiseComputable(userDataPromise, async i => (i == null ? undefined : (await i).ActiveFeatureFlags.includes("TenantStats") || null));
        const hasBillingPageFeature = promiseComputable(userDataPromise, async i => (i == null ? undefined : (await i).ActiveFeatureFlags.includes("BillingStatsPage") || null));
        const hasShowDataSourceTestModeSyncFeature = promiseComputable(userDataPromise, async i => (i == null ? undefined : (await i).ActiveFeatureFlags.includes("ShowDataSourceTestModeSync") || null));
        const hasMultiFactorAuthenticationFeature = promiseComputable(userDataPromise, async userDataPromise => {
            if (userDataPromise == null) return null;
            if (!(await userDataPromise).ActiveFeatureFlags.includes("MultiFactorAuthentication")) return null;
            const url = await getUrl("multiFactorAuthenticationUserData");
            const result = await (await fetch(url)).json();
            return result.IsStateUser;
        });
        const hasDeploymentToggle = promiseComputable(userDataPromise, async i => (i == null ? undefined : (await i).ActiveFeatureFlags.includes("DeploymentToggle") || null));
        const hasOverrideableNow = promiseComputable(userDataPromise, async i => (i == null ? undefined : (await i).ActiveFeatureFlags.includes(KnownFeatureFlags.OverridableNow.Id) || null));
        const overriddenNow = ref(null as Date | null);
        let getOverriddenNowTimeoutId: null | ReturnType<typeof setTimeout> = null;
        const setOverriddenNow = () => {
            if (mainStore.globalConfigData?.OverriddenNow == null) {
                return null;
            }
            overriddenNow.value = mainStore.now();
            const autoAdvance = mainStore.globalConfigData.OverriddenNowAutoAdvance ?? true;
            if (autoAdvance) {
                getOverriddenNowTimeoutId = setTimeout(setOverriddenNow, 1000);
            }
        };
        onUnmounted(() => clearTimeout(getOverriddenNowTimeoutId!));
        watch(
            () => mainStore.globalConfigData,
            () => {
                clearTimeout(getOverriddenNowTimeoutId!);
                setOverriddenNow();
            },
            { immediate: true, deep: true },
        );
        const isBridgeBrandEnabled = promiseComputable(userDataPromise, async i => (i == null ? undefined : (await i).ActiveFeatureFlags.includes("EngagementBuilderBridgeBrand") || null));
        const customFooterHtml = promiseComputable(tenantDataPromise, async i => {
            return i == null ? undefined : (await i).customFooterHtml || null;
        });
        const isPublicAreaEnabled = promiseComputable(tenantDataPromise, async i => (i == null ? undefined : (await i).publicAreaEnabled));
        const isAdminAreaEnabled = promiseComputable(tenantDataPromise, async i => (i == null ? undefined : (await i).adminAreaEnabled));
        const allLoaded = computed(() => {
            return (Object.keys(loaded.value) as (keyof typeof loaded.value)[]).every(k => (component.value == "IndexPage" && k == "siteCss") || (isAdminComponent.value && (k == "siteCss" || k == "siteIndexCss")) || loaded.value[k]);
        });
        const dataPromise = computed(async () => {
            const [userData, tenantData] = await Promise.all([userDataPromise.value, tenantDataPromise.value]);
            return {
                userData,
                tenantData,
            };
        });

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const showModalFunc = (component: any, params: any, events?: any, bind?: any) => {
            const isOpened = resolvablePromise<void>();
            events ??= {};
            const a = events.opened;
            events.opened = () => {
                if (a != null) {
                    a();
                }
                isOpened.resolve();
            };
            vfm.show(
                {
                    component: ModalWrapper,
                    on: events,
                    bind, // https://v3.vue-final-modal.org/guide/properties
                },
                {
                    innerComponent: markRaw(component),
                    innerParams: params,
                },
            );
            return isOpened;
        };
        provide(showModalInjectKey, showModalFunc);

        watch(
            () => needsLogin.value,
            () => {
                if (!needsLogin.value) return;
                const wasLoggedIn = unref(isLoggedIn);
                showModalFunc(LoginModal, {
                    callback: mainStore.needsLoginPromise!.resolve,
                    mode: mainStore.needsLoginMode!.RequiredMode,
                    isLoggedIn: mainStore.needsLoginMode!.IsLoggedIn,
                    wasLoggedIn: wasLoggedIn,
                    isLoggedInNow: async () => {
                        await loadUserData();
                        return isLoggedIn;
                    },
                });
            },
        );

        let cookieTimerId: ReturnType<typeof setInterval> | undefined = undefined;
        onUnmounted(() => clearInterval(cookieTimerId));
        cookieStore.get("AuthTimeout").then((c: { value: string; name: string } | null) => {
            const handleAuthTimeout = (c: string | null | undefined) => {
                sessionTimeout.value = c != null ? new Date(c) : null;
            };
            if (c != null && c.value != null && new Date(c.value) > new Date()) {
                handleAuthTimeout(c.value);
            }
            cookieTimerId = setInterval(() => {
                cookieStore.get("AuthTimeout").then((newC: { value: string; name: string } | null) => {
                    if (newC != null && newC.value != c?.value) {
                        //debugger;
                        handleAuthTimeout(newC.value);
                        c = newC;
                        // TODO native support where available?
                        //cookieStore.dispatchEvent(new CookieChangeEvent("change", {
                        //    changed: [
                        //        {
                        //            name: newC.name,
                        //            value: newC.value,
                        //            domain: newC.domain,
                        //            secure: newC.secure,
                        //            expires: newC.expires,
                        //        }
                        //    ]
                        //}));
                    }
                });
            }, 500);
            //cookieStore.addEventListener('change', event => {
            //    console.log(event);
            //    for(const cookie in event.changed) {
            //        if (cookie.name == "AuthTimeout") {
            //            handleAuthTimeout(cookie.value);
            //        }
            //    }
            //});
        });
        let sessionTimeoutNoticeTimerId: ReturnType<typeof setInterval> | undefined = undefined;
        let sessionTimeoutExpiredTimerId: ReturnType<typeof setInterval> | undefined = undefined;
        const sessionTimeWaitInSeconds = 60;
        let sessionWatchActive = true;
        let userActionDetected = false;
        //const now = ref(new Date());
        //setInterval(() => { now.value = new Date() }, 100);
        //const relativeSessionTimeout = computed(() => {
        //    const sessionTimeoutValue = sessionTimeout.value;
        //    return sessionTimeoutValue == null
        //        ? null
        //        : sessionTimeoutValue.getTime() - now.value.getTime();
        //});
        watch(
            () => sessionTimeout.value,
            sessionTimeoutValue => {
                if (!sessionWatchActive) {
                    return;
                }
                clearTimeout(sessionTimeoutNoticeTimerId);
                clearTimeout(sessionTimeoutExpiredTimerId);
                showLoginExpiringBanner.value = false;
                if (sessionTimeoutValue == null) {
                    return;
                }
                const relativeSessionTimeout = sessionTimeoutValue.getTime() - new Date().getTime();
                sessionTimeoutNoticeTimerId = setTimeout(
                    async () => {
                        showLoginExpiringBanner.value = false;
                        if (!hasSession.value) {
                            return;
                        }
                        if (!userActionDetected) {
                            const wasLoggedIn = isLoggedIn.value;
                            if (wasLoggedIn) {
                                showLoginExpiringBanner.value = true;
                            } else {
                                sessionWatchActive = false;
                                await ensureSiteCssLoaded();
                                showModalFunc(
                                    SessionExpiringModal,
                                    {
                                        asModal: true,
                                        expireTime: sessionTimeoutValue,
                                        isLoggedIn: wasLoggedIn,
                                    },
                                    {
                                        "before-close"() {
                                            nextTick(() => {
                                                sessionWatchActive = true;
                                                triggerRef(sessionTimeout);
                                            });
                                        },
                                        closed() {
                                            nextTick(() => {
                                                sessionWatchActive = true;
                                                triggerRef(sessionTimeout);
                                            });
                                        },
                                    },
                                );
                            }
                        } else {
                            const refreshUrl = await getUrl("extendSession");
                            await fetch(refreshUrl, { headers: { "X-Session-Refresh": "force" } });
                            triggerRef(sessionTimeout);
                        }
                        setTimeout(() => {
                            userActionDetected = false;
                        }, 5000);
                    },
                    relativeSessionTimeout - sessionTimeWaitInSeconds * 1000,
                );
                // TODO  force session expire?
                sessionTimeoutExpiredTimerId = setTimeout(() => {
                    if (!hasSession.value) {
                        return;
                    }
                    clearTimeout(sessionTimeoutNoticeTimerId);
                    clearTimeout(sessionTimeoutExpiredTimerId);
                    cookieStore.delete("AuthTimeout");
                    if (!isLoggedIn.value) {
                        changePage({ page: "SessionExpired" });
                    }
                }, relativeSessionTimeout);
            },
        );
        const keepAliveEvent = () => {
            if (!userActionDetected && sessionWatchActive) {
                userActionDetected = true;
            }
        };
        onMounted(() => {
            document.addEventListener("click", keepAliveEvent, { passive: true });
            document.addEventListener("keyup", keepAliveEvent, { passive: true });
        });
        onUnmounted(() => {
            document.removeEventListener("click", keepAliveEvent);
            document.removeEventListener("keyup", keepAliveEvent);
        });
        watch(
            () => isAdminComponent.value,
            isAdminComponent => {
                var html = document.getElementsByTagName("html")[0];
                var body = document.getElementsByTagName("body")[0];
                if (isAdminComponent) {
                    html.classList.remove("public-page-html");
                    html.classList.add("admin-page-html");
                    body.classList.remove("public-page-body");
                    body.classList.add("admin-page-body");
                } else {
                    html.classList.remove("admin-page-html");
                    html.classList.add("public-page-html");
                    body.classList.remove("admin-page-body");
                    body.classList.add("public-page-body");
                }
            },
        );
        watch(
            () => isPageEditorComponent.value,
            isPageEditorComponent => {
                var html = document.getElementsByTagName("html")[0];
                var body = document.getElementsByTagName("body")[0];
                if (isPageEditorComponent) {
                    html.classList.add("page-editor-page-html");
                    body.classList.add("page-editor-page-body");
                } else {
                    html.classList.remove("page-editor-page-html");
                    body.classList.remove("page-editor-page-body");
                }
            },
        );

        const setLoaded = (key: keyof (typeof loaded)["value"]) => {
            loaded.value[key] = true;
        };
        watch(
            () => mainStore.shouldEnsureSiteCssLoaded,
            async ensureSiteCssLoadedVal => {
                if (ensureSiteCssLoadedVal) {
                    await ensureSiteCssLoaded();
                }
            },
        );
        const ensureSiteCssLoaded = () => {
            if (isAdminComponent.value) {
                return Promise.resolve(null);
            }
            shouldLoadSiteCss.value = true;
            return new Promise(resolve => {
                //cspell:ignore unwatch
                let unwatch: null | ReturnType<typeof watch> = null;
                unwatch = watch(
                    () => loaded.value.siteCss,
                    async (newValue, _) => {
                        if (newValue) {
                            resolve(null);
                            mainStore.siteCssLoaded.resolve();
                            if (unwatch) {
                                unwatch();
                            } else {
                                setTimeout(() => {
                                    (unwatch as ReturnType<typeof watch>)();
                                }, 0);
                            }
                        }
                    },
                    { immediate: true },
                );
            });
        };
        const pageToUrl = (event: ChangePageEvent): Promise<string> => {
            let urlType: string | null = null!;
            let settings = event.settings;
            switch (event.page) {
                case "NotFound":
                    urlType = "notFound";
                    break;
                case "PageNotFound":
                    urlType = "pageNotFound";
                    break;
                case "Offline":
                    urlType = "offline";
                    break;
                case "AccessDenied":
                    urlType = "accessDenied";
                    break;
                case "Index":
                case "IndexPage":
                    urlType = "index";
                    break;
                case "Directory":
                    urlType = "directory";
                    break;
                case "Page":
                    if (settings?.formId != null) {
                        urlType = "draftGoto";
                    } else if (settings?.urlSlug != null) {
                        urlType = "formGotoSlug";
                    } else {
                        urlType = "formGoto";
                    }
                    break;
                case "PageSlug":
                    urlType = "formGotoSlug";
                    break;
                case "PageResumeFailure":
                    urlType = "pageResumeFailure";
                    break;
                case "Error":
                    urlType = "error";
                    break;
                case "Admin":
                    urlType = "admin";
                    break;
                case "AdminSearch":
                    urlType = "adminSearch";
                    break;
                case "AdminSearchReview":
                    urlType = "adminSearchReview";
                    break;
                case "AdminTasksIndex":
                    urlType = "adminTasksIndex";
                    break;
                case "AdminPage":
                    urlType = "adminPageIndex";
                    break;
                case "AdminPageNew":
                    urlType = "adminPageNew";
                    break;
                case "AdminPageEdit":
                    urlType = "adminPageEdit";
                    break;
                case "AdminPageDiscontinue":
                    urlType = "adminPageDiscontinue";
                    break;
                case "AdminPageTransfer":
                    urlType = "adminPageTransfer";
                    break;
                case "AdminDataSources":
                    urlType = "adminDataSources";
                    break;
                case "AdminAddressBook":
                    urlType = "adminAddressBook";
                    break;
                case "AdminGuidedSearch":
                    urlType = "adminGuidedSearch";
                    break;
                case "AdminFeatureFlags":
                    urlType = "adminFeatureFlags";
                    break;
                case "AdminTenantManagementIndex":
                    urlType = "adminTenantManagementIndex";
                    break;
                case "AdminTenantManagementNew":
                    urlType = "adminTenantManagementNew";
                    break;
                case "AdminTenantManagementEdit":
                    urlType = "adminTenantManagementEdit";
                    break;
                case "AdminFormImport":
                    urlType = "adminFormImport";
                    break;
                case "AdminWidget":
                    urlType = "adminWidget";
                    break;
                case "AdminUserManagementUsers":
                    urlType = "adminUserManagementUsers";
                    break;
                case "AdminUserManagementGroups":
                    urlType = "adminUserManagementGroups";
                    break;
                case "AdminStats":
                    urlType = "adminStats";
                    break;
                case "AdminBilling":
                    urlType = "adminBilling";
                    break;
                case "MultiFactorAuthenticationIndex":
                    urlType = "multiFactorAuthenticationIndex";
                    break;
                case "MultiFactorAuthenticationEdit":
                    urlType = "multiFactorAuthenticationEdit";
                    break;
                case "MultiFactorAuthenticationGenerateToken":
                    urlType = "multiFactorAuthenticationGenerateToken";
                    break;
                case "MultiFactorAuthenticationVerifyToken":
                    urlType = "multiFactorAuthenticationVerifyToken";
                    break;
                default:
                    throw new Error(JSON.stringify(event));
            }
            return getUrl(urlType, settings);
        };
        const changePage = (event: ChangePageEvent, replaceHistory?: boolean, skipHistory?: boolean, openInNewTab?: boolean): void => {
            if (openInNewTab) {
                pageToUrl(event).then(u => {
                    window.open(u, "_blank");
                });
                return;
            }
            component.value = null;
            nextTick(() => {
                let _isPageEditorComponent, _isAdminComponent, _component, _componentSettings;
                if (event.page == "NotFound") {
                    _isAdminComponent = false;
                    _component = "NotFound";
                } else if (event.page == "PageNotFound") {
                    _isAdminComponent = false;
                    _component = "PageNotFound";
                    _componentSettings = event.settings;
                } else if (event.page == "Offline") {
                    _isAdminComponent = false;
                    _component = "Offline";
                } else if (event.page == "AccessDenied") {
                    _isAdminComponent = false;
                    _component = "AccessDenied";
                } else if (event.page == "Error") {
                    _isAdminComponent = false;
                    _component = "Error";
                } else if (event.page == "SessionExpired") {
                    _isAdminComponent = false;
                    _component = "SessionExpired";
                } else if (event.page == "Index" || event.page == "IndexPage") {
                    if (isPublicAreaEnabled.value === false || isAdminComponent.value === true) {
                        getUrl("index").then(u => {
                            window.location = u as any; // eslint-disable-line @typescript-eslint/no-explicit-any
                        });
                        return;
                    } else if (isPublicAreaEnabled.value === undefined) {
                        if (tenantDataPromise.value == null) {
                            debugger;
                        }
                        let unwatch: null | ReturnType<typeof watch> = null;
                        unwatch = watch(
                            () => isPublicAreaEnabled.value,
                            () => {
                                changePage(event, replaceHistory, skipHistory);
                                if ((unwatch as unknown) != null) {
                                    unwatch!();
                                }
                            },
                        );
                        return;
                    } else {
                        _isAdminComponent = false;
                        _component = "IndexPage";
                    }
                } else if (event.page == "Directory" || event.page == "DirectoryPage") {
                    _isAdminComponent = false;
                    _component = "DirectoryPage";
                } else if (event.page == "Page" || event.page == "PagePage") {
                    if (event.settings?.adminReview) {
                        _isAdminComponent = true;
                    } else {
                        _isAdminComponent = false;
                    }
                    _componentSettings = event.settings;
                    _component = "PagePage";
                } else if (event.page == "PageResumeFailure") {
                    _componentSettings = event.settings;
                    _component = "PageResumeFailure";
                } else if (event.page == "Admin") {
                    if (isAdminAreaEnabled.value === false || isAdminComponent.value === false) {
                        getUrl("admin").then(u => {
                            window.location = u as any; // eslint-disable-line @typescript-eslint/no-explicit-any
                        });
                        return;
                    } else if (isAdminAreaEnabled.value === undefined) {
                        if (tenantDataPromise.value == null) {
                            debugger;
                        }
                        let unwatch: null | ReturnType<typeof watch> = null;
                        unwatch = watch(
                            () => isPublicAreaEnabled.value,
                            () => {
                                changePage(event, replaceHistory, skipHistory);
                                if ((unwatch as unknown) != null) {
                                    unwatch!();
                                }
                            },
                        );
                        return;
                    } else {
                        _isAdminComponent = true;
                        _component = "Admin";
                    }
                } else if (event.page == "AdminSearch") {
                    _isAdminComponent = true;
                    _component = "AdminSearch";
                    _componentSettings = event.settings;
                } else if (event.page == "AdminTasksIndex") {
                    _isAdminComponent = true;
                    _component = "AdminTasksIndex";
                } else if (event.page == "AdminPage") {
                    _isAdminComponent = true;
                    _component = "AdminPage";
                    _componentSettings = event.settings;
                } else if (event.page == "AdminPageNew") {
                    _isAdminComponent = true;
                    _component = "AdminPageNew";
                    _componentSettings = event.settings;
                } else if (event.page == "AdminPageEdit") {
                    _isAdminComponent = true;
                    _isPageEditorComponent = true;
                    _component = "AdminPageEdit";
                    _componentSettings = event.settings;
                } else if (event.page == "AdminPageDiscontinue") {
                    _isAdminComponent = true;
                    _component = "AdminPageDiscontinue";
                    _componentSettings = event.settings;
                } else if (event.page == "AdminPageTransfer") {
                    _isAdminComponent = true;
                    _component = "AdminPageTransfer";
                    _componentSettings = event.settings;
                } else if (event.page == "AdminDataSources") {
                    _isAdminComponent = true;
                    _component = "AdminDataSources";
                    _componentSettings = event.settings;
                } else if (event.page == "AdminAddressBook") {
                    _isAdminComponent = true;
                    _component = "AdminAddressBook";
                } else if (event.page == "AdminGuidedSearch") {
                    _isAdminComponent = true;
                    _component = "AdminGuidedSearch";
                    _componentSettings = event.settings;
                } else if (event.page == "AdminFeatureFlags") {
                    _isAdminComponent = true;
                    _component = "AdminFeatureFlags";
                } else if (event.page == "AdminTenantManagementIndex") {
                    _isAdminComponent = true;
                    _component = "AdminTenantManagementIndex";
                } else if (event.page == "AdminTenantManagementNew") {
                    _isAdminComponent = true;
                    _component = "AdminTenantManagementNew";
                } else if (event.page == "AdminTenantManagementEdit") {
                    _isAdminComponent = true;
                    _component = "AdminTenantManagementEdit";
                    _componentSettings = event.settings;
                } else if (event.page == "AdminFormImport") {
                    _isAdminComponent = true;
                    _component = "AdminFormImport";
                } else if (event.page == "AdminWidget") {
                    _isAdminComponent = true;
                    _component = "AdminWidget";
                    _componentSettings = event.settings;
                } else if (event.page == "AdminUserManagementUsers") {
                    _isAdminComponent = true;
                    _component = "AdminUserManagementUsers";
                } else if (event.page == "AdminUserManagementGroups") {
                    _isAdminComponent = true;
                    _component = "AdminUserManagementGroups";
                } else if (event.page == "AdminStats") {
                    _isAdminComponent = true;
                    _component = "AdminStats";
                    _componentSettings = event.settings;
                } else if (event.page == "AdminBilling") {
                    _isAdminComponent = true;
                    _component = "AdminBilling";
                    _componentSettings = event.settings;
                } else if (event.page == "MultiFactorAuthenticationIndex") {
                    _isAdminComponent = false;
                    _component = "MultiFactorAuthenticationIndex";
                } else if (event.page == "MultiFactorAuthenticationEdit") {
                    _isAdminComponent = false;
                    _component = "MultiFactorAuthenticationEdit";
                    _componentSettings = event.settings;
                } else if (event.page == "MultiFactorAuthenticationGenerateToken") {
                    _isAdminComponent = false;
                    _component = "MultiFactorAuthenticationVerifyToken";
                    _componentSettings = event.settings;
                } else if (event.page == "MultiFactorAuthenticationVerifyToken") {
                    _isAdminComponent = false;
                    _component = "MultiFactorAuthenticationVerifyToken";
                    _componentSettings = event.settings;
                }
                if (!skipHistory) {
                    pushHistoryState(_component, _componentSettings, replaceHistory ?? false);
                }
                componentSettings.value = _componentSettings;
                component.value = _component;
                isAdminComponent.value = _isAdminComponent ?? false;
                isPageEditorComponent.value = _isPageEditorComponent ?? false;
                updateDocumentTitle();
            });
        };
        provide(changePageInjectKey, changePage);
        const updatePage = (event: ChangePageEvent["settings"], replaceHistory?: boolean) => {
            const componentSettings2 = { ...componentSettings.value, ...event };
            pushHistoryState(component.value, componentSettings2, replaceHistory);
            componentSettings.value = componentSettings2;
            updateDocumentTitle();
        };
        const updateDocumentTitle = async () => {
            if (["NotFound", "PageNotFound", "Error", "IndexPage", "DirectoryPage", "PagePage"].includes(component.value as string)) {
                let title = "";
                switch (component.value) {
                    case "NotFound":
                    case "PageNotFound":
                        title = "Page Not Found";
                        break;
                    case "Error":
                        title = "Error";
                        break;
                    case "IndexPage":
                        title = "Home";
                        break;
                    case "DirectoryPage":
                        title = "Directory";
                        break;
                    case "PagePage":
                        if (componentSettings.value?.title && !isAdminComponent.value) {
                            title = componentSettings.value.title;
                        } else {
                            return;
                        }
                        break;
                    default:
                        return;
                }
                const displayLabel = await displayLabelCache.value[mainStore.tenantCode];
                document.title = displayLabel ? `${title}: ${displayLabel}` : title;
            }
        };
        provide(updatePageInjectKey, updatePage);
        const pushHistoryState = async (component: string | null | undefined, componentSettings: ChangePageEvent["settings"], replaceHistory?: boolean) => {
            let url: string;
            if (component == "IndexPage") {
                url = await getUrl("index");
            } else if (component == "DirectoryPage") {
                url = await getUrl("directory");
            } else if (component == "Admin") {
                url = await getUrl("admin");
            } else if (component == "AdminSearch") {
                url = await getUrl("adminSearch", componentSettings);
            } else if (component == "AdminTasksIndex") {
                url = await getUrl("adminTasksIndex");
            } else if (component == "AdminPage") {
                url = await getUrl("adminPageIndex", componentSettings);
            } else if (component == "AdminPageEdit") {
                url = await getUrl("adminPageEdit", componentSettings);
            } else if (component == "AdminPageDiscontinue") {
                url = await getUrl("adminPageDiscontinue", componentSettings);
            } else if (component == "AdminPageTransfer") {
                url = await getUrl("adminPageTransfer", componentSettings);
            } else if (component == "AdminPageNew") {
                url = await getUrl("adminPageNew", componentSettings);
            } else if (component == "AdminDataSources") {
                url = await getUrl("adminDataSources", componentSettings);
            } else if (component == "AdminAddressBook") {
                url = await getUrl("adminAddressBook");
            } else if (component == "AdminGuidedSearch") {
                url = await getUrl("adminGuidedSearch", componentSettings);
            } else if (component == "AdminFeatureFlags") {
                url = await getUrl("adminFeatureFlags");
            } else if (component == "AdminTenantManagementIndex") {
                url = await getUrl("adminTenantManagementIndex");
            } else if (component == "AdminTenantManagementNew") {
                url = await getUrl("adminTenantManagementNew");
            } else if (component == "AdminTenantManagementEdit") {
                url = await getUrl("adminTenantManagementEdit", componentSettings);
            } else if (component == "AdminFormImport") {
                url = await getUrl("adminFormImport", componentSettings);
            } else if (component == "AdminWidget") {
                url = await getUrl("adminWidget", componentSettings);
            } else if (component == "AdminUserManagementUsers") {
                url = await getUrl("adminUserManagementUsers", componentSettings);
            } else if (component == "AdminUserManagementGroups") {
                url = await getUrl("adminUserManagementGroups", componentSettings);
            } else if (component == "AdminStats") {
                url = await getUrl("adminStats", componentSettings);
            } else if (component == "AdminBilling") {
                url = await getUrl("adminBilling", componentSettings);
            } else if (component == "MultiFactorAuthenticationIndex") {
                url = await getUrl("multiFactorAuthenticationIndex");
            } else if (component == "MultiFactorAuthenticationEdit") {
                url = await getUrl("multiFactorAuthenticationEdit", componentSettings);
            } else if (component == "MultiFactorAuthenticationVerifyToken") {
                url = await getUrl("multiFactorAuthenticationVerifyToken", componentSettings);
            } else if (component == "PageResumeFailure") {
                url = await getUrl("resumeDraftFailure", componentSettings);
            } else if (component == "PagePage") {
                if (componentSettings?.adminPreview) {
                    // TODO typing
                    if ((componentSettings.form as unknown as Record<string, string>).formHash) {
                        url = await getUrl("adminPagePreview", componentSettings);
                    } else {
                        return; // Posted admin preview shouldn't have history changes
                    }
                } else if (componentSettings?.adminReview) {
                    url = await getUrl("adminSearchReview", componentSettings);
                } else if (componentSettings?.formId) {
                    url = await getUrl("draftGoto", componentSettings);
                } else if (componentSettings?.urlSlug) {
                    url = await getUrl(componentSettings?.testMode ? "testFormGotoSlug" : "formGotoSlug", componentSettings);
                } else {
                    url = await getUrl(componentSettings?.testMode ? "testFormGoto" : "formGoto", componentSettings);
                }
            } else if (component == "SessionExpired" || component == "NotFound" || component == "PageNotFound" || component == "Offline" || component == "AccessDenied" || component == "Error") {
                return; // these shouldn't have history changes
            } else {
                throw new Error(`Unknown component: ${component}`);
            }
            replaceHistory = replaceHistory || ((componentSettings && !!componentSettings.replaceHistory) ?? false);

            if (url != window.location.pathname) {
                const gaData = mainStore.tenantData.googleAnalyticsIds || [];
                for (let index = 0; index <= gaData.length; index++) {
                    if (!gaData[index]) continue;
                    if (gaData[index] && gaData[index].startsWith("UA")) {
                        window.ga(`tracker${index}.set`, "page", url);
                        window.ga(`tracker${index}.send`, "pageview");
                    } else {
                        window.gtag("event", "page_view", {
                            send_to: gaData[index],
                            page_title: document.title,
                            page_location: window.location.href,
                            page_path: window.location.pathname,
                        });
                    }
                }
            }
            currentUrl.value = url;
            window.history[replaceHistory ? "replaceState" : "pushState"]({ component: component, settings: componentSettings }, null!, url);
        };
        const setupGoogleAnalytics = () => {
            const gaData = mainStore.tenantData.googleAnalyticsIds || [];
            if (!shouldSkipGaSetup) {
                // Universal Analytics
                (function (i, s, o, g, r, a, m) {
                    //@ts-expect-error
                    i["GoogleAnalyticsObject"] = r;
                    //@ts-expect-error
                    (i[r] =
                        //@ts-expect-error
                        i[r] ||
                        function () {
                            //@ts-expect-error
                            (i[r].q = i[r].q || []).push(arguments);
                        }),
                        //@ts-expect-error
                        (i[r].l = 1 * (new Date() as unknown as number));
                    //@ts-expect-error
                    (a = s.createElement(o)), (m = s.getElementsByTagName(o)[0]);
                    //@ts-expect-error
                    a.async = 1;
                    //@ts-expect-error
                    a.src = g;
                    //@ts-expect-error
                    m.parentNode.insertBefore(a, m);
                })(window, document, "script", "https://www.google-analytics.com/analytics.js", "ga");

                // Google Analytics 4
                const scriptTag = document.createElement("script");
                const bodyScript = document.getElementsByTagName("script")[0];
                scriptTag.src = `https://www.googletagmanager.com/gtag/js?id=${gaData[0]}`;
                scriptTag.async = true;
                bodyScript.parentNode!.insertBefore(scriptTag, bodyScript);

                window.dataLayer = window.dataLayer || [];
                window.gtag = function () {
                    window.dataLayer.push(arguments);
                };
                window.gtag("js", new Date());
            }
            for (let index = 0; index < gaData.length; index++) {
                if (!gaData[index]) continue;
                if (gaData[index].startsWith("UA")) {
                    window.ga("create", {
                        trackingId: gaData[index],
                        cookieDomain: "auto",
                        cookieFlags: "SameSite=None; Secure",
                        name: `tracker${index}`,
                    });
                    window.ga(`tracker${index}.set`, "page", window.location);
                    window.ga(`tracker${index}.send`, "pageview");
                } else {
                    window.gtag("config", gaData[index], {
                        cookie_domain: "auto",
                        cookie_flags: "SameSite=None; Secure",
                    });
                }
            }
        };
        const applyHistoryState = (event: PopStateEvent) => {
            const newState = event.state;
            if (newState && newState.component) {
                component.value = null;
                changePage({ page: newState.component, settings: newState.settings }, false, true);
            }
        };
        const showSearch = (data: { userData: UserData | null; tenantData: TenantData | null }) => {
            showModalFunc(
                NavSearchModal,
                {
                    data,
                    searchText: searchText.value,
                    updateSearchText(text: string) {
                        searchText.value = text;
                    },
                },
                {},
                {
                    styles: { "align-items": "start", top: "25vh" },
                    "overlay-style": { "background-color": "rgba(0, 0, 0, 0.9)" },
                    "click-to-close": false,
                    "esc-to-close": false,
                },
            );
        };
        const toggleMobileNav = () => {
            showMobileNav.value = !showMobileNav.value;
            if (!showMobileNav.value) {
                showAdminMenu.value = false;
            }
        };
        const globalClick = (_: MouseEvent) => {
            // hide dropdowns when clicking anywhere else on the page
            var togglers = document.querySelectorAll(".dropdown-toggle");
            if (togglers.length > 0) {
                for (var i = 0; i < togglers.length; i++) {
                    togglers[i].classList.remove("show");
                }
            }
        };
        const loadUserData = async () => {
            userDataPromise.value = getUrl("getUserData")
                .then(url => fetch(url, { cache: "reload" }))
                .then(r => r.json());
            const data = await userDataPromise.value;
            const token = data.XrsfToken;
            mainStore.xrsfToken = token;
            mainStore.hasSession = data.HasSubmissions;
            mainStore.activeFeatureFlagsPromise.resolve(data.ActiveFeatureFlags);
            mainStore.setUserData(data);
        };
        provide(loadUserDataInjectKey, loadUserData);
        const loadTenantData = () => {
            return (tenantDataPromise.value = Promise.resolve(mainStore.tenantData));
        };
        const getLoginUrl = (mode?: string | number, returnUrl?: string) => {
            return getUrl("login", {
                returnUrl: returnUrl || currentUrl.value || window.location.toString(),
                mode,
            });
        };
        provide(getLoginUrlInjectKey, getLoginUrl);
        const getLogoutUrl = (returnUrl?: string) => {
            return getUrl("logout", {
                returnUrl: returnUrl || currentUrl.value || window.location.toString(),
            });
        };
        const getUrl = async (key: string, data?: { [key: string]: string | number | boolean | null | undefined }): Promise<string> => {
            const tenantData = await tenantDataPromise.value!;
            const isDefaultTenant = data == null || ((data.tenantUrlSlug == null || data.tenantUrlSlug == tenantData.tenantUrlSlug) && ((data.tenantCode || data.tenant) == null || (data.tenantCode || data.tenant) == tenantData.tenantCode));
            const template = tenantData.urlTemplates[isDefaultTenant ? "defaultTenant" : "tenanted"][key];
            if (template == null || template.template == null) throw new Error(isDefaultTenant + "," + key + "\n" + JSON.stringify(template) + "\n" + JSON.stringify(tenantData.urlTemplates));
            let urlData = new URL(template.template, window.location.href);
            let url = urlData.pathname;
            let urlParamsFlip = Array.from(urlData.searchParams.entries()).reduce(
                (a, [k, v]) => {
                    a[v] = k;
                    return a;
                },
                {} as Record<string, string>,
            );
            const templateParamMap = Object.keys(template)
                .filter(i => i != "template")
                .reduce(
                    (a, c) => {
                        a[c] = template[c];
                        return a;
                    },
                    {} as Record<string, string>,
                );
            var queryString = new URLSearchParams();
            data = data || {};
            //if (isDefaultTenant) {
            //    data.tenantUrlSlug = null;
            //    data.tenantCode = null;
            //    data.tenant = null;
            //} else {
            if ("tenantUrlSlug" in template) {
                if (data.tenantUrlSlug) {
                    // OK
                } else if (data.tenantCode) {
                    data.tenantUrlSlug = await mainStore.getTenantUrlSlug([data.tenantCode as string])[data.tenantCode as string];
                } else if (data.tenant) {
                    //debugger;
                    console.trace("Tenant"); //To be migrated
                    data.tenantUrlSlug = await mainStore.getTenantUrlSlug([data.tenant as string])[data.tenant as string];
                } else {
                    data.tenantUrlSlug = tenantData.tenantUrlSlug;
                }
            }
            //}
            if ("areaUrlSlug" in template) {
                if (data.areaUrlSlug) {
                    // OK
                } else if (data.areaCode) {
                    data.areaUrlSlug = await mainStore.getTenantUrlSlug([data.areaCode as string])[data.areaCode as string];
                } else if (data.area) {
                    debugger;
                    console.trace("Area"); //To be migrated
                    data.areaUrlSlug = await mainStore.getTenantUrlSlug([data.area as string])[data.area as string];
                } else {
                    data.areaUrlSlug = data.tenantUrlSlug;
                }
            }
            //if (key == "siteDesignIndexStyles") {
            //    debugger;
            //}
            Object.keys(data).forEach(key => {
                if (template[key]) {
                    if (url.includes(template[key])) {
                        url = url.split(template[key]).join(encodeURIComponent(data![key] != null ? data![key]! : "")); // replace all
                    } else if (data![key] != null && data![key] !== "") {
                        queryString.append(urlParamsFlip[templateParamMap[key]], data![key]!.toString());
                        //} else {
                        //    debugger;
                    }
                    //} else {
                    //    debugger;
                }
            });
            if (Array.from(queryString.keys()).length > 0) {
                url = url + "?" + queryString.toString();
            }
            return new URL(url, urlData).href;
        };
        const setComponentFromUrl = async () => {
            const extraDataElement = document.getElementById("extraData");
            if (extraDataElement != null) {
                const extraDataKey = extraDataElement.dataset["extradataKey"];
                if (extraDataKey != null) {
                    if (extraDataKey == "NotFound" || extraDataKey == "PageNotFound" || extraDataKey == "Error" || extraDataKey == "Offline" || extraDataKey == "AccessDenied") {
                        changePage({ page: extraDataKey }, true);
                        return;
                    }
                }
            }
            const tenantData = await tenantDataPromise.value!;
            function escapeRegExp(string: string) {
                return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"); // $& means the whole matched string
            }
            function templatesToRegex(key: string) {
                return [templateToRegex("defaultTenant", key), templateToRegex("tenanted", key)];
            }
            function templateToRegex(type: keyof TenantData["urlTemplates"], key: string) {
                const templateData = tenantData!.urlTemplates[type][key];
                const partKeys = Object.keys(templateData).filter(i => i != "template");
                const partFlip = partKeys.reduce(
                    (a, c) => {
                        a[templateData[c]] = c;
                        return a;
                    },
                    {} as Record<string, string>,
                );
                if (templateData == null) throw new Error(type + "," + key + "\n" + JSON.stringify(tenantData!.urlTemplates));
                const templateUrl = new URL(templateData.template, window.location.href);
                const templateUrlWithoutQueryString = templateUrl.pathname;
                const templateQueryStringKeys = Array.from(templateUrl.searchParams.entries()).reduce(
                    (r, [k, v]) => {
                        //if (k != partFlip[v]) { debugger;}
                        r[k] = partFlip[v];
                        return r;
                    },
                    {} as Record<string, string>,
                );
                //const templateQueryString = templateUrl.searchParams;
                //const templateFixedQueryString = new URLSearchParams();
                //const templateQueryStringKeys = [];
                //for(const key in templateQueryString.keys()) {
                //    if (partKeys.includes(s)) {
                //        templateQueryStringKeys.push(key);
                //    } else {
                //        for(const v in templateQueryString.getAll()) {
                //            templateFixedQueryString.append(key, v);
                //        }
                //    }
                //}
                //const templateFixedQueryStringStr = templateFixedQueryString.toString();
                //if (templateFixedQueryStringStr != "") {
                //    templateUrlWithoutQueryString = templateUrlWithoutQueryString + "?" + templateFixedQueryString;
                //}
                let regex = "";
                const matches = [];
                let lastIndex = 0;
                if (partKeys.length > 0) {
                    const partRegex = new RegExp(
                        "(.*?)(" +
                            partKeys
                                .map(i => templateData[i])
                                .map(escapeRegExp)
                                .join("|") +
                            ")",
                        "g",
                    );
                    //if (key == "adminDataSources" && type == "tenanted") {
                    //    console.trace(templateUrlWithoutQueryString);
                    //    console.trace(partKeys);
                    //    console.trace(partRegex);
                    //}
                    let match = null;
                    let optional = 0;
                    let first = 1;
                    while ((match = partRegex.exec(templateUrlWithoutQueryString))) {
                        let groupRegex = "([^/=?]*)";
                        const part = partFlip[match[2]];
                        if ((key == "testFormGoto" || key == "formGoto" || key == "formGotoLegacy") && part == "formName") {
                            groupRegex = "([a-zA-Z0-9]{8}(?:-[a-zA-Z0-9]{4}){3}-[a-zA-Z0-9]{12})";
                        }
                        var prefix = match[1];
                        if (first || (prefix != "/" && prefix != "" && prefix[0] != "?" && prefix[0] != "&")) {
                            if (optional > 0) {
                                debugger;
                                throw new Error("TODO");
                            }
                        } else {
                            optional++;
                            groupRegex = "(?:" + escapeRegExp(prefix) + "(?:" + groupRegex;
                            prefix = "";
                        }
                        regex = regex + escapeRegExp(prefix) + groupRegex;
                        matches.push(part);
                        lastIndex = match.index + match[0].length;
                        first = 0;
                    }
                    while (optional-- > 0) {
                        if (lastIndex == templateUrlWithoutQueryString.length) {
                            regex += ")?)?";
                        } else {
                            regex += "))";
                        }
                    }
                }
                regex = regex + escapeRegExp(templateUrlWithoutQueryString.substring(lastIndex));
                regex = "^" + regex + "[/]?$";
                return { regex: new RegExp(regex, "i"), matches, queryStringMatches: templateQueryStringKeys };
            }
            var patterns = {
                Preview: templatesToRegex("adminPreview"),
                Preview2: templatesToRegex("adminPagePreview"),
                Index: templatesToRegex("index"),
                Directory: templatesToRegex("directory"),
                Page: templatesToRegex("testFormGoto"),
                PageSlug: templatesToRegex("testFormGotoSlug"),
                PageResumeFailure: templatesToRegex("resumeDraftFailure"),

                PageLegacy: templatesToRegex("formGotoLegacy"),
                PageSlugLegacy: templatesToRegex("formGotoSlugLegacy"),
                Draft: templatesToRegex("draftGoto"),
                SubmitDraftSuccess: templatesToRegex("submitDraftSuccess"),
                Admin: templatesToRegex("admin"),
                AdminSearch: templatesToRegex("adminSearch"),
                AdminTasksIndex: templatesToRegex("adminTasksIndex"),
                AdminSearchReview: templatesToRegex("adminSearchReview"),
                AdminPage: templatesToRegex("adminPageIndex"),
                AdminPageEdit: templatesToRegex("adminPageEdit"),
                AdminPageNew: templatesToRegex("adminPageNew"),
                AdminPageDiscontinue: templatesToRegex("adminPageDiscontinue"),
                AdminPageTransfer: templatesToRegex("adminPageTransfer"),
                AdminDataSources: templatesToRegex("adminDataSources"),
                AdminAddressBook: templatesToRegex("adminAddressBook"),
                AdminGuidedSearch: templatesToRegex("adminGuidedSearch"),
                AdminFeatureFlags: templatesToRegex("adminFeatureFlags"),
                AdminTenantManagementIndex: templatesToRegex("adminTenantManagementIndex"),
                AdminTenantManagementNew: templatesToRegex("adminTenantManagementNew"),
                AdminTenantManagementEdit: templatesToRegex("adminTenantManagementEdit"),
                AdminFormImport: templatesToRegex("adminFormImport"),
                AdminWidget: templatesToRegex("adminWidget"),
                AdminUserManagementUsers: templatesToRegex("adminUserManagementUsers"),
                AdminUserManagementGroups: templatesToRegex("adminUserManagementGroups"),
                AdminStats: templatesToRegex("adminStats"),
                AdminBilling: templatesToRegex("adminBilling"),
                MultiFactorAuthenticationIndex: templatesToRegex("multiFactorAuthenticationIndex"),
                MultiFactorAuthenticationEdit: templatesToRegex("multiFactorAuthenticationEdit"),
                MultiFactorAuthenticationVerifyToken: templatesToRegex("multiFactorAuthenticationVerifyToken"),
            };
            let urlKey = null;
            let urlData = null! as Record<string, string | null>;
            const url = window.location.pathname;
            const searchParams = new URLSearchParams(window.location.search);
            (["AdminPageEdit", "AdminStats", "AdminTenantManagementIndex", "Page", "PageLegacy"] as (keyof typeof patterns)[]).concat(Object.keys(patterns) as (keyof typeof patterns)[]).some(k => {
                let urlMatch = null! as RegExpExecArray;
                for (const pattern of patterns[k]) {
                    const { regex, queryStringMatches, matches } = pattern;
                    if ((urlMatch = regex.exec(url)!)) {
                        urlData = {};
                        matches.forEach((v, k) => {
                            const m = urlMatch![k + 1];
                            urlData[v] = m == null ? null : decodeURIComponent(m);
                        });
                        Object.entries(queryStringMatches).forEach(([k, v]) => {
                            if (searchParams.has(k)) {
                                urlData[v] = searchParams.get(k);
                            }
                        });
                        urlKey = k;
                    }
                }
                return !!urlMatch;
            });
            if (urlKey == "AdminPageEdit") {
                const extraDataElement = document.getElementById("extraData");
                if (extraDataElement != null && extraDataElement.dataset["extradataKey"] == "AdminPagePreview") {
                    urlKey = "Preview";
                }
            }
            if (urlKey == "Preview" || urlKey == "Preview2") {
                changePage({ page: "Page", settings: { adminPreview: true } }, true, true);
            } else if (urlKey == "AdminSearchReview") {
                changePage({ page: "Page", settings: { adminReview: true, ...urlData } }, true, true);
            } else {
                if (urlKey == "PageSlug" && urlData.page == null) {
                    if ((await mainStore.getTenantCodeByUrlSlug([urlData.areaUrlSlug!])[urlData.areaUrlSlug!]) == null) {
                        // area token isn't an area URL slug, so probably actually a legacy page slug URL
                        urlData.page = urlData.urlSlug;
                        urlData.urlSlug = urlData.areaUrlSlug;
                        urlData.areaUrlSlug = urlData.tenantUrlSlug;
                    }
                } else if (urlKey == "Page" && urlData.page == null) {
                    if ((await mainStore.getTenantCodeByUrlSlug([urlData.areaUrlSlug!])[urlData.areaUrlSlug!]) == null) {
                        // area token isn't an area URL slug, so probably actually a legacy page slug URL
                        urlData.page = urlData.formName;
                        urlData.urlSlug = urlData.areaUrlSlug;
                        urlData.areaUrlSlug = urlData.tenantUrlSlug;
                    }
                } else if (urlKey == "PageLegacy") {
                    if (!/^(0|[0-9A-F]{8}[-]?(?:[0-9A-F]{4}[-]?){3}[0-9A-F]{12})$/.test(urlData.formVersion!)) {
                        urlKey = "PageSlug";
                        urlData.urlSlug = urlData.formVersion;
                        urlData.areaUrlSlug = urlData.formName;
                        urlData.formName = null;
                        urlData.formVersion = null;
                        if (searchParams.has("testMode")) {
                            urlData.testMode = searchParams.get("testMode");
                        }
                        if (searchParams.has("formHash")) {
                            urlData.formHash = searchParams.get("formHash");
                        }
                    } else {
                        urlKey = "Page";
                        urlData.areaUrlSlug = urlData.tenantUrlSlug;
                    }
                } else if (urlKey == "PageSlugLegacy") {
                    urlKey = "PageSlug";
                    urlData.areaUrlSlug = urlData.tenantUrlSlug;
                }
                urlKey = urlKey == "Draft" || urlKey == "PageSlug" || urlKey == "SubmitDraftSuccess" ? "Page" : urlKey;
                if (urlKey == null) {
                    //throw new Error("A: [" + patterns.Admin[0].regex + "] [" + JSON.stringify(patterns.Admin[0]) + "] [" + url + "][" + patterns.Admin[0].regex.test(url) + "]");
                    urlKey = "NotFound";
                    urlData = {};
                }
                changePage({ page: urlKey, settings: urlData }, true, true);
            }
        };

        document.getElementsByTagName("body")[0].classList.add("public-page-body");
        mainStore.urlFetcher = getUrl.bind(this);
        loadTenantData();
        tenantDataPromise.value!.then(data => {
            displayLabelCache.value[data.tenantCode] = Promise.resolve(data.tenantDisplayLabel);
        });
        setupGoogleAnalytics();
        setComponentFromUrl().then(() => {
            if (userDataPromise.value == null) {
                const isLoggedIn = document.cookie.split(";").some(item => item.trim().startsWith("DF_LoggedIn="));
                if (isLoggedIn) {
                    loadUserData();
                } else {
                    const userDataString = document.getElementById("anonUserData")!.innerHTML;
                    let userData = null;
                    if (userDataString == "/*6eb984f0-9ecc-4224-ac82-d82eee040f8e*/") {
                        userData = {}; // TODO
                    } else {
                        userData = JSON.parse(userDataString);
                    }
                    userDataPromise.value = Promise.resolve(userData);
                    mainStore.activeFeatureFlagsPromise.resolve(userData.ActiveFeatureFlags);
                    mainStore.setUserData(userData);
                }
            }
        });
        window.addEventListener("popstate", applyHistoryState);
        window.addEventListener(
            "keypress",
            (event: KeyboardEvent) => {
                if ((event.target as HTMLElement).nodeName == "A" && ((event.code != null ? event.code == "Enter" : event.keyCode == 13) || (event.code != null ? event.code == "Space" : event.keyCode == 32))) {
                    (event.target as HTMLElement).click();
                }
            },
            { passive: true },
        );

        return {
            //state
            component,
            componentSettings,
            currentUrl,
            displayLabelCache,
            isAdminAreaEnabled,
            isAdminComponent,
            isPageEditorComponent,
            isPublicAreaEnabled,
            loaded,
            searchRootTenant,
            searchTextDebounced,
            searchVisible,
            shouldLoadSiteCss,
            showAdminMenu,
            showMobileNav,
            tenantDataPromise,
            userDataPromise,

            // computed
            allLoaded,
            dataPromise,
            needsLogin,
            hasVersionMismatch,
            siteDesignIndexStylesUrl,
            siteDesignStylesUrl,
            sessionTimeout,
            showLoginExpiringBanner,
            isLoggedIn,
            hasSession,
            //relativeSessionTimeout,

            // async computed
            customFooterHtml,
            hasBillingPageFeature,
            hasShowDataSourceTestModeSyncFeature,
            hasMultiFactorAuthenticationFeature,
            hasPageworkflowFeature,
            hasTenantStatsFeature,
            isUserLoginDisabled,
            hasDeploymentToggle,
            hasOverrideableNow,
            overriddenNow,
            isBridgeBrandEnabled,

            // Methods
            changePage,
            setLoaded,
            ensureSiteCssLoaded,
            getLoginUrl,
            getLogoutUrl,
            getUrl,
            globalClick,
            loadUserData,
            showSearch,
            toggleDropdown,
            toggleMobileNav,
            updatePage,
        };
    },
    components: {
        SessionExpiringModal,
        LoadingWheel,
        Promised,
        ModalsContainer,
        VersionMismatchBanner,
        LiveRegion,
        IndexPage,
        Offline: () => ({
            loading: LoadingPlaceholder,
            component: import(/* webpackChunkName: "PageHttpStatus", webpackPrefetch: true */ "./public/pages/Offline.3.vue"),
        }),
        Error: defineAsyncComponent({
            loadingComponent: LoadingPlaceholder,
            loader: () => import(/* webpackChunkName: "PageHttpStatus" */ "./public/pages/Error.3.vue"),
        }),
        NotFound: defineAsyncComponent({
            loadingComponent: LoadingPlaceholder,
            loader: () => import(/* webpackChunkName: "PageHttpStatus" */ "./public/pages/NotFound.3.vue"),
        }),
        PageNotFound: defineAsyncComponent({
            loadingComponent: LoadingPlaceholder,
            loader: () => import(/* webpackChunkName: "PageHttpStatus" */ "./public/pages/PageNotFound.3.vue"),
        }),
        AccessDenied: defineAsyncComponent({
            loadingComponent: LoadingPlaceholder,
            loader: () => import(/* webpackChunkName: "PageHttpStatus" */ "./public/pages/AccessDenied.3.vue"),
        }),
        SessionExpired: defineAsyncComponent({
            loadingComponent: LoadingPlaceholder,
            loader: () => import(/* webpackChunkName: "PageHttpStatus" */ "./public/pages/SessionExpired.3.vue"),
        }),
        DirectoryPage: defineAsyncComponent({
            loadingComponent: LoadingPlaceholder,
            loader: () => import(/* webpackChunkName: "PageDirectory" */ "./public/pages/Directory.3.vue"),
        }),
        PagePage: defineAsyncComponent({
            loadingComponent: LoadingPlaceholder,
            loader: () => import(/* webpackChunkName: "PagePage" */ "./public/pages/Page.3.vue"),
        }),
        PageResumeFailure: defineAsyncComponent({
            loadingComponent: LoadingPlaceholder,
            loader: () => import(/* webpackChunkName: "PagePage" */ "./public/pages/PageResumeFailure.3.vue"),
        }),
        Admin: defineAsyncComponent({
            loadingComponent: LoadingPlaceholder,
            loader: () => import(/* webpackChunkName: "Admin" */ "./admin/Index.3.vue"),
        }),
        AdminSearch: defineAsyncComponent({
            loadingComponent: LoadingPlaceholder,
            loader: () => import(/* webpackChunkName: "Admin" */ "./admin/pages/Search/Search.3.vue"),
        }),
        AdminTasksIndex: defineAsyncComponent({
            loadingComponent: LoadingPlaceholder,
            loader: () => import(/* webpackChunkName: "Admin" */ "./admin/pages/Tasks/Index.3.vue"),
        }),
        AdminDataSources: defineAsyncComponent({
            loadingComponent: LoadingPlaceholder,
            loader: () => import(/* webpackChunkName: "Admin" */ "./admin/pages/DataSources/DataSources.3.vue"),
        }),
        AdminAddressBook: defineAsyncComponent({
            loadingComponent: LoadingPlaceholder,
            loader: () => import(/* webpackChunkName: "Admin" */ "./admin/pages/AddressBook/AddressBook.3.vue"),
        }),
        AdminGuidedSearch: defineAsyncComponent({
            loadingComponent: LoadingPlaceholder,
            loader: () => import(/* webpackChunkName: "Admin" */ "./admin/pages/GuidedSearch/GuidedSearch.3.vue"),
        }),
        AdminPage: defineAsyncComponent({
            loadingComponent: LoadingPlaceholder,
            loader: () => import(/* webpackChunkName: "AdminPage" */ "./admin/pages/Page/Index.3.vue"),
        }),
        AdminPageNew: defineAsyncComponent({
            loadingComponent: LoadingPlaceholder,
            loader: () => import(/* webpackChunkName: "AdminPage" */ "./admin/pages/Page/New.3.vue"),
        }),
        AdminPageEdit: defineAsyncComponent({
            loadingComponent: LoadingPlaceholder,
            loader: () => import(/* webpackChunkName: "AdminPage" */ "./admin/pages/Page/Edit.3.vue"),
        }),
        AdminPageDiscontinue: defineAsyncComponent({
            loadingComponent: LoadingPlaceholder,
            loader: () => import(/* webpackChunkName: "AdminPage" */ "./admin/pages/Page/Discontinue.3.vue"),
        }),
        AdminPageTransfer: defineAsyncComponent({
            loadingComponent: LoadingPlaceholder,
            loader: () => import(/* webpackChunkName: "AdminPage" */ "./admin/pages/Page/Transfer.3.vue"),
        }),
        AdminFeatureFlags: defineAsyncComponent({
            loadingComponent: LoadingPlaceholder,
            loader: () => import(/* webpackChunkName: "Admin" */ "./admin/pages/FeatureFlags/FeatureFlags.3.vue"),
        }),
        AdminTenantManagementIndex: defineAsyncComponent({
            loadingComponent: LoadingPlaceholder,
            loader: () => import(/* webpackChunkName: "Admin" */ "./admin/pages/Tenant/Index.3.vue"),
        }),
        AdminTenantManagementNew: defineAsyncComponent({
            loadingComponent: LoadingPlaceholder,
            loader: () => import(/* webpackChunkName: "Admin" */ "./admin/pages/Tenant/New.3.vue"),
        }),
        AdminTenantManagementEdit: defineAsyncComponent({
            loadingComponent: LoadingPlaceholder,
            loader: () => import(/* webpackChunkName: "Admin" */ "./admin/pages/Tenant/Edit.3.vue"),
        }),
        AdminFormImport: defineAsyncComponent({
            loadingComponent: LoadingPlaceholder,
            loader: () => import(/* webpackChunkName: "Admin" */ "./admin/pages/FormImport/FormImport.3.vue"),
        }),
        AdminWidget: defineAsyncComponent({
            loadingComponent: LoadingPlaceholder,
            loader: () => import(/* webpackChunkName: "Admin" */ "./admin/pages/Widget/Widget.3.vue"),
        }),
        AdminUserManagementUsers: defineAsyncComponent({
            loadingComponent: LoadingPlaceholder,
            loader: () => import(/* webpackChunkName: "Admin" */ "./admin/pages/UserManagement/Users.3.vue"),
        }),
        AdminUserManagementGroups: defineAsyncComponent({
            loadingComponent: LoadingPlaceholder,
            loader: () => import(/* webpackChunkName: "Admin" */ "./admin/pages/UserManagement/Groups.3.vue"),
        }),
        AdminStats: defineAsyncComponent({
            loadingComponent: LoadingPlaceholder,
            loader: () => import(/* webpackChunkName: "Admin" */ "./admin/pages/Stats/Stats.3.vue"),
        }),
        AdminBilling: defineAsyncComponent({
            loadingComponent: LoadingPlaceholder,
            loader: () => import(/* webpackChunkName: "Admin" */ "./admin/pages/Stats/Billing.3.vue"),
        }),
        MultiFactorAuthenticationIndex: defineAsyncComponent({
            loadingComponent: LoadingPlaceholder,
            loader: () => import(/* webpackChunkName: "Wisconsin" */ "./wi/public/pages/MultiFactorAuthentication/Index.3.vue"),
        }),
        MultiFactorAuthenticationEdit: defineAsyncComponent({
            loadingComponent: LoadingPlaceholder,
            loader: () => import(/* webpackChunkName: "Wisconsin" */ "./wi/public/pages/MultiFactorAuthentication/Edit.3.vue"),
        }),
        MultiFactorAuthenticationVerifyToken: defineAsyncComponent({
            loadingComponent: LoadingPlaceholder,
            loader: () => import(/* webpackChunkName: "Wisconsin" */ "./wi/public/pages/MultiFactorAuthentication/VerifyToken.3.vue"),
        }),
        nonIndexStyles: defineAsyncComponent({
            loader: () => import(/* webpackChunkName: "nonIndexStyles", webpackPrefetch: true */ "./lib/nonIndexStyles.3.vue"),
        }),
        AdminStyles: defineAsyncComponent({
            loader: () => import(/* webpackChunkName: "AdminStyles", webpackPrefetch: true */ "./admin/lib/AdminStyles.3.vue"),
        }),
    },
});
</script>
